import React from "react";
import "./App.css";
import Header from "./components/Header/Header";
import { TypeAnimation } from "react-type-animation";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="p-6 text-green-400 font-bold">
        <TypeAnimation
          sequence={[
            // Same substring at the start will only be typed once, initially
            "I imagine",
            1000,
            "I design",
            1000,
            "I code",
            1000,
            "I transform ideas into reality",
            1000,
            "I create experiences",
            1000,
          ]}
          speed={50}
          style={{ fontSize: "2em" }}
          repeat={Infinity}
          deletionSpeed={70}
        />
      </div>
      <div className="p-6 text-violet-400 font-bold text-4xl ">
        This website is under construction, check back later!
      </div>
    </div>
  );
}

export default App;
