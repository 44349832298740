import {CSSProperties, ReactNode} from 'react';
import 'tailwindcss/tailwind.css'

interface ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  style?: CSSProperties;
}

function Button({children}: ButtonProps) {
  return (
    <button className="font-bold text-violet-200 hover:text-violet-600">
      {children}
    </button>
  );
}

export default Button;