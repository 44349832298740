import React from 'react';
import Button from '../Button/Button';
import { useState } from 'react';
import { Bars3BottomRightIcon, XMarkIcon } from '@heroicons/react/20/solid';


function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const colors = ['text-violet-100', 'text-violet-200', 'text-violet-300', 'text-violet-400', 'text-violet-500', 'text-violet-600', 'text-violet-700', 'text-violet-800'];

  return (
    <header className="md:flex md:justify-between md:items-center p-6">
      <div className="flex justify-between items-center">
        <h1 className="text-4xl font-bold">
          <span className="text-violet-50">&lt;</span>
          {Array.from('Seemdev').map((letter, index) => (
            <span className={colors[index % colors.length]} key={index}>
              {letter}
            </span>
          ))}
          <span className="text-violet-900">/</span>
          <span className="text-violet-950">&gt;</span>
        </h1>

        <div className="md:hidden">
          <button type="button" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <XMarkIcon className="h-8 w-8 text-violet-400" /> : <Bars3BottomRightIcon className="h-8 w-8 text-violet-400" />}
          </button>
        </div>
      </div>

      <div className={`md:flex space-x-6 pt-2.5 ${isOpen ? 'flex' : 'hidden'}`}>
        <Button>&lt;Start/&gt;</Button>
        <Button>&lt;About/&gt;</Button>
        <Button>&lt;Contact/&gt;</Button>
      </div>
    </header>
  );
}

export default Header;